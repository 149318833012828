$garanteasy-green: rgb(223, 0, 0);
$garanteasy-green-text: #000000;
$garanteasy-green-background: rgb(51, 50, 51);
$garanteasy-blue: rgb(11, 86, 166);
$garanteasy-gray: rgb(135, 135, 135);
$garanteasy-black: #333333;
$garanteasy-text-list-group-active: #fdfdfb;

$brand-primary:         $garanteasy-green !default;
$brand-info:         $garanteasy-blue !default;
$brand-info-color:         $garanteasy-blue !default;
$brand-info-bg:         white !default;

$body-bg:    white !default;
$text-color: $garanteasy-black;

$btn-default-color:              white !default;
$btn-default-disabled-color: lighten( $garanteasy-green, 20%);
$btn-default-bg:                 $garanteasy-green !default;
$btn-default-border:             darken($btn-default-bg, 5%) !default;

$btn-info-bg: white !default;
$btn-info-color: $garanteasy-blue !default;
$btn-warning-bg: $garanteasy-green !default;
$navbar-fixed-bottom-active-color: yellow;

$font-family-sans-serif:  "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family-base:        $font-family-sans-serif !default;
// $font-size-base:          12px !default;
